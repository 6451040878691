<template>
  <div loldocup-main-symbol>
    <img src="/img/pages/loldocup/symbol.png" class="img-logo"/>
    <p class="snow"></p>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: "LoldocupMainSymbol",
  data() {
    return {
      snowContainer: null,
      map: [],
    }
  },
  computed: {
    snowSize() {
      return this.matchedMediaDevice === 'M' ? 10 : this.matchedMediaDevice === 'T' ? 12 : 14;
    }
  },
  methods: {
    addSnow() {
      if (!this.snowContainer) this.snowContainer = this.$el.querySelector('.snow');
      const snow = document.createElement('i');
      let sv = Math.random()*.5 + .5;
      snow.classList.add('fall');
      snow.style.left = (20 + (Math.random() * (this.snowContainer.offsetWidth - 40))) + 'px';
      snow.style.opacity = String(sv);
      snow.style.fontSize = Math.random() * this.snowSize + this.snowSize + 'px';
      snow.innerHTML = '❄';
      this.snowContainer.appendChild(snow);

      const s = setTimeout(() => {
        snow.remove();
        _.remove(this.map,s);
      },10000);
      this.map.push(s);
      this.update();
    },
    update() {
      this.map.push(setTimeout(this.addSnow, Math.random() * 300 + 700));
    }
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    _.forEach(this.map , o => {
      clearTimeout(o);
    });
  }
}
</script>

<style lang="less">
@import '~@shared/less/proj';
[loldocup-main-symbol] { .mt(-70); .wh(335); .mh-c; .rel; .crop; pointer-events: none;
  > img { .wh(100%); }
  > .snow { .abs; .wh(100%); .lt(0, 0); .z(2);
    > i { animation: fall 10s linear forwards; .c(#fff); .abs;}
  }
  @media (@tl-up) {
    .ib; .vat; .wh(376); .mt(-100);
  }
  @media (@ds-up) {
    .wh(480); .mt(-140);
  }

  @keyframes fall {
    to {
      transform: translateY(40vh);
      opacity: 0;
    }
  }
}
</style>
