<template>
  <div gift-box-symbols>
    <img class="bg l" src="/img/pages/loldocup/home/pc/back-L.svg" />
    <img class="bg r" src="/img/pages/loldocup/home/pc/back-R.svg" />
    <p class="gift l" />
    <p class="gift r" />
  </div>
</template>

<script>
export default {
  name: 'GiftBoxSymbols',
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gift-box-symbols] { pointer-events: none; .rel; .z(2); .wh(100%, 60);
  &:before { .cnt; .bgc(#c1d6ff); .wh(100vw, 100%); .abs; .lt(50%, 0); .t-xc; .z(2);}
  > .bg { .hide;}
  > .gift { .abs; .z(3);
    &.l {.lb(0, 26); .contain('@{do-home}/gift_graphic_L.svg'); .wh(215, 150);}
    &.r {.rb(-44, 54); .contain('@{do-home}/gift_graphic_R.svg'); .wh(172, 123);}
  ;
  }
  @media (@tl-up) {
    .wh(1024, 100); .mh-c;
    > .gift {
      &.l {.lb(-80, 38); .contain('@{do-home}/t/gift_graphic_L.svg'); .wh(374, 650);}
      &.r {.rb(-88, 80); .contain('@{do-home}/t/gift_graphic_R.svg'); .wh(352, 460);}
    ;
    }
    > .bg { .abs; .w(250); .z(2); .b(60); .block;
      &.l { .l(-176);}
      &.r { .r(-176);}
    }
  }
  @media (@ds-up) {
    .wh(1260, 180);
    > .gift {
      &.l {.lb(-260, 70); .contain('@{do-home}/pc/gift_graphic_L.svg'); .wh(553, 963);}
      &.r {.rb(-245, 113); .contain('@{do-home}/pc/gift_graphic_R.svg'); .wh(504, 657);}
    ;
    }
    > .bg { .abs; .w(320); .z(2); .b(180);
      &.l { .l(-270);}
      &.r { .r(-270);}
    }
  }
}
</style>
