<template>
  <div loldocup-home>
    <HomeHeader />
    <LoldocupLeague />
    <client-only><MyInfo /></client-only>
  </div>
</template>

<script>
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import MyInfo from '@/views/components/gameHome/MyInfo.vue';
import LoldocupLeague from '@/views/components/gameHome/loldocup/LoldocupLeague.vue';
import meta from '@/data/meta';

export default {
  name: 'LoldocupHome',
  components: { LoldocupLeague, MyInfo, HomeHeader },
  computed: {
    timeDiff() {
      if(['development', 'local'].includes(process.env.VUE_APP_ENV)) return 0;
      return this.$store.getters.timeDiff ?? 0;
    },
  },
  methods: {
    checkedQuery() {
      if(this.$route.query.page && this.$route.query.page === 'arena') {
        setTimeout(() => {
          const media = this.matchedMediaDevice;
          const target = document.querySelector('.league');
          this.$scroll.scrollMove(target.offsetTop + (media === 'D' ? 720 : 450), 1000);
        }, 200);
      }
    },
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },
  mounted() {
    this.checkedQuery();
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj';

[loldocup-home] {}

</style>
