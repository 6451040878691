<template>
  <PItem tag="h2" loldocup-star-title :class="theme">
    <span class="s">
      <SvgDotL class="dot" />
      <em class="star-holder">
        <SvgStar class="star" />
      </em>
      <SvgDotR class="dot" />
    </span>
    <span class="l right"><em class="star-holder">
      <SvgStar class="star" />
    </em>
      <SvgDotR class="dot" />
    </span>
    <span class="l left">
      <SvgDotL class="dot" />
      <em class="star-holder">
        <SvgStar class="star" />
      </em></span>
    <slot />
  </PItem>
</template>

<script>
import SvgDotL from '@/views/components/gameHome/loldocup/SvgDotL.vue';
import SvgStar from '@/views/components/gameHome/loldocup/SvgStar.vue';
import SvgDotR from '@/views/components/gameHome/loldocup/SvgDotR.vue';

export default {
  name: 'LoldocupStarTitle',
  components: { SvgDotR, SvgStar, SvgDotL },
  props: {
    theme: { type: String, default: 'purple' },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[loldocup-star-title] { .rel; .ib; .bold; .fs(32); .ls(-2);
  .intro-up(1, {
    .star-holder { animation-duration: .8s; animation-name: star-scale; }
  });
  span { .h(22); .lh(0) !important;}
  .star { .block; }
  > .s { .block; .mb(8);
    .dot {.ib; .vam; .wh(52, 5);}
    .star-holder {.wh(23, 22); .mh(8); .ib; .vam;
      .star {.wh(100%); .rotate; }
    }
  }
  .l { .hide; }

  &.purple { .c(#8a1686);
    .fill-target { .fill(#8a1686); }
  }
  &.blue { .c(#230d55);
    .fill-target { .fill(#230d55); }
  }
  &.white { .c(white);
    .fill-target { .fill(white); }
  }

  @media (@tl-up) {
    .fs(44); .lh(40);
    span { .h(auto); }
    > .s { .hide; }
    > .l { .ib-list; .abs; .block; .t(50%); .t-yc;
      > * { .vam;}
      &.right {.r(-144);
        .star-holder { .mr(10); }
      }
      &.left {.l(-144);
        .star-holder { .ml(10); }
      }
      > .dot {.ib; .vam; .w(72);}
      > .star-holder {.ib; .wh(38); .vam;
        .star { .wh(100%); .rotate; }
      }
    }
  }
  @keyframes star-scale {
    from { .t-s(1); }
    20% { .t-s(.8); }
    60% { .t-s(1.2);}
    100% { .t-s(1);}
  }
}
</style>
