<template>
  <div loldocup-league>
    <PSection class="progress-holder" theme="white">
      <p class="key-visual" />
      <div class="inside">
        <SectionTitle title="OFFICIAL LEAGUE" title-ko="공식 대회" black />
        <a
          class="btn-ask"
          href="https://discord.gg/PkpEJxM98K"
          target="_blank"
          @click="trackEvent(`${upRouteGameId}_donation_Main_discord_btn`, 'click', `${upRouteGameId}`)"
          v-prx:intro="{ r: [1, 4], ty: [20, 0], o: [0, 1] }"
        >
          <span>대회운영 문의<em>|</em>친구 찾기 기능 제공</span>
        </a>
        <PItem class="schedule">
          <LoldocupMainSymbol />
          <PItem class="info-holder">
            <h4>모일수록 행복해지는 <br />협곡 토너먼트</h4>
            <p>LCK프로, 연예인, 인플루언서 그리고 아마추어가 <br class="hidden-ds-up" />함께 참여하는 <br class="hidden-tl-down" />총 상금 2,000만원과 <br class="hidden-ds-up" />기부자 대표 자격을 건 연말 공식대회!</p>
            <div class="date">
              <p><em>예선</em>
                <span>12월 11일(토) ~ 12월 12일(일)</span>
              </p>
              <p><em>본선</em>
                <span>12월 15일(수) ~ 12월 18일(토)</span>
              </p>
            </div>
          </PItem>
          <button @click="goIntro">대회 안내 바로가기</button>
        </PItem>
        <div class="donation">
          <LoldocupStarTitle><b>2021 롤 도네이션 컵</b>에 참가해서 <br />함께 따뜻한 연말을 즐겨보세요</LoldocupStarTitle>
          <PItem class="live">
            <h4>실시간 모금액
              <svg-title-tail />
            </h4>
            <div class="cost">
              <div class="coupon">
                <p class="value"><em>₩</em>{{ donationCommaValue }}</p>
              </div>
              <p class="due">2021년 12월 8일 09:00 기준</p>
            </div>
            <p class="copy">
              참가 신청만 해도 <em>1,000원 기부</em>와<br /> 좋아하는 <em>스타의 애장품 응모</em>까지!
            </p>
          </PItem>
          <PItem class="thing">
            <h4>스타 애장품
              <svg-title-tail />
            </h4>
            <figure>
              <img src="/img/pages/loldocup/home/pc/img_star_products.jpg" />
            </figure>
            <p class="message"><b>17명의 스타</b>가 준비한 20종의 <b>애장품</b>에 응모하세요!</p>
            <p class="gift-name"><b>감스트</b> 키보드 / <b>DK 칸</b> 유니폼<br><b>(여자)아이들 미연</b> 자켓 &amp; <b>소연</b> 스케이트 보드</p>
          </PItem>
          <button @click="goEvent">애장품 응모하기</button>
        </div>
        <div class="league">
          <LoldocupStarTitle>신청 기간<b> : 2021년 12월 10일 (금) 21시</b></LoldocupStarTitle>
          <Slider :info="cardList" :option="option" v-if="cardList" ref="slider" navigation slides-per-view="auto">
            <template v-slot:slide="{info}">
              <PItem class="p-item">
                <CdnImg :src="imageObj(info).image" />
                <div class="info-holder">
                  <p class="status-tag ready" :class="info.statusTag">{{ statusTag(info) }}</p>
                  <h4>{{ info.title || '' }}</h4>
                  <ul>
                    <li>
                      <span>대회 시간</span>
                      <em>{{ startTime(info) }} KST</em></li>
                    <li>
                      <span>대회 모드</span>
                      <em>{{ modeSize(info) }}</em></li>
                    <li>
                      <span>대회 날짜</span>
                      <em>{{ startDateFormat(info) }}</em></li>
                    <li>
                      <span>남은 시간</span>
                      <CountDown v-if="info.schedule" :end-time="info.schedule.start" />
                    </li>
                  </ul>
                </div>
                <button class="apply-btn" @click="goArena(info)">참가 신청</button>
              </PItem>
            </template>
          </Slider>
        </div>
        <GiftBoxSymbols />
      </div>
    </PSection>
  </div>
</template>

<script>
import _ from 'lodash';
import gtag from '@shared/mixins/gtag';
import Slider from 'shared/components/common/Slider.vue';
import moment from 'moment-timezone';
import CdnImg from 'shared/components/common/CdnImg.vue';
import { commaDecimal, countAni, readNumberAsKorean } from 'shared/utils/numberUtils';
import { historyMove } from 'shared/utils/routerUtils';
import CountDown from '@/views/components/common/CountDown.vue';
import GiftBoxSymbols from './GiftBoxSymbols.vue';
import SvgTitleTail from '@/views/components/gameHome/loldocup/SvgTitleTail.vue';
import SectionTitle from '@/views/components/common/SectionTitle.vue';
import { MAIN_URL } from '@/env';
import LoldocupMainSymbol from '@/views/components/gameHome/loldocup/LoldocupMainSymbol.vue';
import LoldocupStarTitle from '@/views/components/loldocup/LoldocupStarTitle.vue';

moment.tz.setDefault('Asia/Seoul');

export default {
  name: 'LoldocupLeague',
  mixins: [gtag],
  components: { LoldocupStarTitle, LoldocupMainSymbol, SvgTitleTail, GiftBoxSymbols, CountDown, Slider, SectionTitle, CdnImg },
  data() {
    return {
      list: [''],
      option: {
        freeMode: true,
        slidesPerView: 'auto',
        breakpoints: {
          1024: { touchRatio: 0 },
        },
      },
      cardOption: {
        order: 'schedule.applyStart ASC',
        q: 'groupCode eq RIOT_LOL_DOCUP_1',
      },
      cardList: null,
      donationValue: 0,
      donationTimer: null,
      countTimer: null,
    };
  },
  computed: {
    donationCommaValue() {
      return commaDecimal(this.donationValue);
    },
    donationKoValue() {
      return readNumberAsKorean(this.donationValue);
    },
    upRouteGameId() {
      return this.$route.params.id.toUpperCase();
    },
    remainStartTime() {
      const now = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const endTime = moment(moment(this.item.schedule.start).format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss');
      const diffDay = moment(endTime).diff(moment(now), 'days');

      return diffDay < 10 ? Number(diffDay.toString().padStart(2, '0')) : diffDay;
    },
    isToday() {
      const format = 'YYYY-MM-DD';
      const contestDate = moment(this.item.schedule.start).format(format);
      const today = moment().format(format);

      return moment(contestDate).isSame(today);
    },
  },
  methods: {
    goEvent() {
      this.$router.push({ name: 'loldocupApplyGift' });
    },
    goIntro() {
      this.trackEvent('Main_landing_btn', 'click', `${this.upRouteGameId}`);
      this.$router.push({ name: 'loldocupLeagueGuide' });
    },
    async updateDonation() {
      clearInterval(this.countTimer);
      const r = await this.$services.home.getLolDonationValue();
      const total = r.totalDonation;
      const before = this.donationValue;
      const gap = !before ? 500 : total - before;
      if (gap === 0) return;

      const start = total - gap;
      this.countTimer = countAni(start < 0 ? 0 : start, total, values => {
        this.donationValue = values.start;
      });

      this.donationTimer = setTimeout(this.updateDonation, 3000);
    },
    async getCardList() {
      this.cardList = (await this.$services.home.getArena(this.cardOption)).items;
      setTimeout(() => {
        const id = _.findIndex(this.cardList, o => o.statusTag === 'ONGOING' || o.statusTag === 'END');
        if (id > 0) this.$refs.slider.go(id);
      }, 300);
    },
    imageObj(item) {
      return item?.images?.vertical;
    },
    backgroundColor(item) {
      return this.imageObj(item).backGroundColor;
    },
    modeSize(item) {
      const size = item.management.mode;
      return `${size} : ${size}`;
    },
    startTime(item) {
      return moment(item.schedule.start).format('HH:mm');
    },
    startDateFormat(item) {
      return moment(item.schedule.start).format('MM월 DD일');
    },
    statusTag(item) {
      switch (item.statusTag) {
        case 'PRE':
          return '접수대기';

        case 'READY':
          return '접수중';

        case 'WAITING_APPLY':
          return '접수대기';

        case 'APPLYING':
          return '접수중';

        case 'ONGOING':
          return '진행중';

        case 'END':
          return '종료';
        default:
          break;
      }
      return null;
    },
    goArena(item) {
      this.trackEvent('Main_arena_go', `click_arena_${item.id}`, `${this.upRouteGameId}`);
      if (item.dummy) {
        if (item.dummyLink) {
          window.open(item.dummyLink);
        } else {
          this.$toast('toast.ready', { type: 'fail' });
        }
      } else {
        historyMove(`${MAIN_URL}/arena/ri/${this.$route.params.id}/${item.id}/info`);
      }
    },
  },
  mounted() {
    this.getCardList();
    this.updateDonation();
  },
  beforeDestroy() {
    clearTimeout(this.donationTimer);
    clearInterval(this.countTimer);
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

[loldocup-league] { .c(white); .bgc(#fbe2ff); overflow: hidden; .rel; .z(1); .font-g;
  .progress-holder { .pt(62); .rel;
    .intro-up(5, {
      //.img-logo { .jello; }
    });
    .key-visual { .abs; .z(1); .wh(100%, 712); .lt(0, 0); .cover('@{do-home}/home-bg.jpg');}
    .inside { .rel; .z(2);
      [section-title] { .pl(20); .rel; z-index: 3; .ib; .vam; .mt(6); .mr(22); .tl;}
      .btn-ask {.wh(40, 40); .rel; z-index: 2; .abs; .br(8); .bgc(#1a4fb0); .ib; .vam; .font-spoqa; .z(1000);
        span {.hide;}
        &:before {.cnt; .wh(24); .contain('@{icon}/discord.svg'); .lt(50%, 50%); .t-xyc; .abs;}
      }
      .schedule { .mt(45); .pb(42); .tc; .ls(-2);
        .intro-up(3, {
          > button { .ani-on; }
        });
        .intro-up(4, {
          [loldocup-main-symbol] { .jello; }
        });
        .info-holder { .mt(-10);
          .intro-up(0, {
            h4, h4 + p { .ani-on; }
          });
          .intro-up(1, {
            .date { .ani-on; }
          });
          h4 { .c(#240d55); .fs(24, 30); .ani-base;
            & + p { .fs(14, 20); .mt(8); .c(#555); .font-spoqa; .c(#555); .ls(0); .ani-base; }
          }
          .date { .bgc(rgba(255, 252, 255, 0.8)); .-a(#efe8f0); .w(300); .mh-c; .p(12, 0); .br(4); .mt(4); .ani-base;
            > p { .ib-list; .mt(4); .ls(0); .font-spoqa;
              &:nth-of-type(1) { .mt(0); }
              &:nth-of-type(2) em { .bgc(#af1b2d); }
              em { .wh(50, 30); .bgc(#555); .bold; .fs(14, 30); .br(30); .vam; .mr(8);}
              span { .c(#555); .fs(14); .ib; .vam;}
            }
          }
        }
        > button { .wh(300, 42); .bgc(#ba2b1c); .br(6); .fs(16, 42); .c(white); .block; .mt(8); .mh-c; .font-g; .ls(-2); .ani-base;}
      }
      [loldocup-star-title] { .mb(78); .regular; .fs(26);}
      .donation { .bgc(white); .p(56, 0, 10); .tc; .ls(-2); .rel; .mb(112);
        > div { .bgc(#f8f1f8); .mt(54); .w(335); .mh-c; .rel; .ani-base;
          .intro-up(1, { .ani-on; });
          &:nth-of-type(1) { .mt(0); }
          h4 { .wh(160, 44); .fs(22, 44); .abs; .lt(0, -30); .bgc(#8a1686); .tl; .pl(28);
            > svg { .wh(35, 44); .abs; .rb(-35, 0);}
          }
          &.live { .p(220, 0, 36); .rel;
            .cost { .abs; .w(352); .lt(50%, 40); .t-xc;
              .coupon { .wh(100%, 115); .p(18, 0, 22); .contain('@{do-home}/pc/fund.svg');
                .value { .c(#d2d60e); text-shadow: 0 4px 4px rgba(0, 0, 0, 0.5); .c(#d2d60e); -webkit-text-stroke: 2px #f5ffcc; .extra-bold; .fs(40); .ls(0);
                  em { .ib; .mr(12);}
                }
                .ko { .font-spoqa; .ls(0); .fs(14); .mt(10); }
              }
              .due { .fs(14); .c(#555); .m(16, 0, 30); .font-spoqa; .ls(0);}
            }
            .copy { .fs(20, 28); .ib; .tc; .c(#4271c7); .rel;
              &:before { .cnt; .contain('@{do-home}/pc/quotes-L.svg'); .wh(14); .abs; .lt(-18, -8);}
              &:after { .cnt; .contain('@{do-home}/pc/quotes-R.svg'); .wh(14); .abs; .rb(-22, -2);}
              em { .c(#8a1686); }
            }
          }
          &.thing { .p(40, 6, 28);
            > figure { .rel; .wh(299, 240); .mh-c; .mb(26); .block;
              &:after { .cnt; .abs; .l(0); .r(0); .t(0); .b(0); .no-repeat('@{do-home}/img_star_products.jpg'); .bg-s(100%, 100%); }
              img { .wh(100%, 100%); .o(0); image-rendering: -webkit-optimize-contrast; }
            }
            > p b { .bold; }
            > .message { .rel; .ib; .w(217); .medium; .fs(18, 22); .c(#8a1686); .tc; .ls(-1px);
              &:before,
              &:after { .cnt; .abs; .t(0); .wh(16, 16); .bgc(#8a1686);
                clip-path: path('M2 12.464H7.056V7.504H4.752C4.752 5.872 5.744 4.688 7.44 4.528V2C3.952 2.288 2 4.912 2 8.336V12.464ZM8.72 12.464H13.776V7.504H11.472C11.472 5.872 12.464 4.688 14.16 4.528V2C10.672 2.288 8.72 4.912 8.72 8.336V12.464Z');
              }
              &:before { .l(-24); }
              &:after { .r(-24); .t-r(180deg); }
            }
            > .gift-name { .mt(8); .font-spoqa; .regular; .fs(12, 16); .c(#4271c7); .ls(0); }
          }
        }
        > button { .block; .wh(335, 54); .bgc(#4271c7); .fs(22, 54); .mh-c; .br(6); .mt(28); .font-g; .ls(-2); .c(white); }
        &:after { .cnt; .wh(100%, 52); .bg('@{do-home}/pc/white_seal_bottom.svg'); .bg-s(auto, 100%); background-repeat: repeat-x; .bg-xc; .abs; .lb(0, -52); .abs;}
      }
      .league { .tc; .bgc(white); .ls(-2); .rel;
        [loldocup-star-title] { .mb(40); .fs(22);
          > .s { .mb(16); }
        }
        &:before { .cnt; .wh(100%, 52); .bg('@{do-home}/pc/white_seal_top.svg'); .bg-s(auto, 100%); background-repeat: repeat-x; .bg-xc; .abs; .lt(0, -52); .abs;}
        &:after { .cnt; .wh(100%, 18); .bgc(#8a1686); }
        [slider] { .mb(120); }
        .swiper-container { overflow: visible; .p(0, 26);}
        .swiper-wrapper { .w(886); .mh-c;}
        .swiper-slide { .c(#000); .w(282); .tl; .ml(20);
          &:nth-of-type(1) { .ml(0); }
          .p-item {.ani-base;
            .intro-up(1, { .ani-on; });
            img { .wh(100%, 196); .block; object-fit: cover; object-position: top;}
            .info-holder { .bgc(#f8f1f8); .p(26, 20, 28); .-box;
              .status-tag { .h(24); .p(0, 8); .fs(12, 24); .font-spoqa; .ls(0);}
              h4 { .fs(20, 26); .ls(-1); .keep-all; .m(12, 0, 18); .min-h(52); .ellipsis(2);}
              ul { .br-b(8); .font-spoqa; .ls(0);
                li {.fs(14); .tl; .mt(14); .pl(32); .rel; .c(#555);
                  > * {.ib;}
                  &:before {.cnt; .abs; .wh(24); .contain('@{do-home}/pc/access_time.svg'); .lt(0, -2); .o(.7);}
                  &:nth-of-type(1) {.mt(0);}
                  &:nth-of-type(2):before {.contain('@{do-home}/pc/insert_emoticon.svg');}
                  &:nth-of-type(3):before {.contain('@{do-home}/pc/date_range.svg');}
                  &:nth-of-type(4):before {.contain('@{do-home}/pc/alarm_on.svg');}
                  span {
                    &:after { .cnt; .ib; .m(0, 8); .vat; .mt(3); .wh(1, 14); .bgc(#555);}
                  }
                }
              }
            }
            .apply-btn { .wh(100%, 48); .block; .bgc(#000); .br(6); .c(white); .fs(16, 48); .tc; .mt(10); .font-g; .ls(-2);
              &:after { .cnt; .ib; .wh(14); .contain('@{do-home}/arrow.svg'); .vam; .ml(8);}
            }
          }
        }
      }
    }
  }
  @media (@tl-up) {
    .progress-holder { .p(100, 0, 0);
      .key-visual {.cover('@{do-home}/pc/home-bg.jpg'); .h(1220);}
      .inside {
        [section-title] { .mb(0); .pl(58);}
        .btn-ask {.wh(288, 48); .fs(14, 48px); .pl(56); .abs; .rt(58, 0);
          span {.ib;
            em {.m(0, 10);}
          }
          &:before {.l(22); .t-y(-50%); .mt(1);}
        }
        .schedule { .rel; .w(908); .mh-c; .mt(80); .mb(50); .pb(0); .clear;
          > img { .ib; .vat; .w(376); .mt(-100);}
          .info-holder {.ib; .vat; .mt(0); .ml(0); .w(446); .fr; .ml(86);
            h4 { .tl; .fs(34, 40); .h(auto);
              & + p { .tl; .mt(8); .fs(18, 26);}
            }
            .date { .w(100%); .tl; .mt(28); .p(14, 0, 14, 24);
              > p { .mt(8);
                em { .tc; .w(60); .fs(16);}
                span { .fs(16); }
              }
            }
          }
          > button { .abs; .lb(60, 10); .w(248);}
        }
        [loldocup-star-title] { .fs(38, 44); .mb(90);
          > .left { .l(-164); }
          > .right { .r(-164); }
        }
        .donation { .w(908); .mh-c; .p(64, 40, 0); .mb(160); .br-t(20);
          > div { .rel; .w(100%);
            h4 { .pl(40); .fs(28, 50); .wh(280, 50);
              > svg { .wh(40, 50); }
            }
            &.live { .p(46, 32, 52, 50); .tl;
              .cost { .w(394); .abs; .rt(33, -22); .t-x(0);
                .coupon { .pt(34); .pb(0); .tc; .h(130);
                  .ko { .fs(11); .mt(4); }
                }
                .due { .mt(12); .tr; .regular; .fs(14); }
              }
              .copy { .fs(22, 36);
                &:before { .wh(15); .lt(-20, -12);}
                &:after { .wh(15); .rb(-30, 2);}
              }
            }
            &.thing { .p(74, 26, 58);
              > figure { .wh(754, 312); .mb(40);
                &:after { .hide; }
                img { .o(1); }
              }
              > .message { .w(auto); .fs(26, 38);
                &:before { .l(-36); }
                &:after { .r(-36); }
              }
              > .gift-name { .hide; }
            }
          }
          > button { .mt(48); .wh(470, 66); .fs(26, 66); }
          &:after { .h(76); .b(-76); }
        }
        .league { .w(908); .mh-c;
          [loldocup-star-title] { .fs(30); .mb(60);
            > .l { .t-sxy(.7, 0, -70%);
              &.left { .l(-124); }
              &.right { .r(-124); }
            }
          }
          &:before { .h(76); .t(-76); }
          .swiper-container { .ph(50);}
          .swiper-wrapper { transform: translate3d(0px, 0px, 0px) !important; .block; .w(auto);}
          .swiper-slide { .w(100%); .rel; .mt(50); .ib; .ml(0);
            &:nth-of-type(1) { .mt(0); }
            .p-item { .pl(408); .rel;
              > img { .wh(408, 284); .abs; .lb(0, 0); }
              .info-holder { .h(258); .p(16, 28);
                > h4 { .min-h(auto); .fs(24); .m(12, 0, 20); .min-h(52);}
              }
              .apply-btn { .abs; .wh(174, 44); .fs(16, 44); .rb(-10, 20); }
            }
          }

        }
      }
    }
  }
  @media (@ds-up) {
    .progress-holder { .p(100, 0, 0);
      .inside { .w(@screen-ds-min); .mh-c; .rel;
        [section-title] { .mb(0); .pl(0);}
        .btn-ask {.rt(0);}
        .schedule { .w(1200); .mb(30);
          > img { .w(480); .mt(-140);}
          .info-holder { .w(652); .ml(28); .mr(40); .mt(66);
            h4 { .fs(40); .mb(12);
              br { .hide; }
              & + p { .fs(20, 26);}
            }
            .date { .w(652); .mt(34); .p(30);
              > p { .ib; .mt(0);
                &:nth-of-type(1) { .mr(28); }
              }
            }
          }
          > button { .lb(116, 10); .w(248);}
        }
        [loldocup-star-title] { .fs(44, 54); .mb(90);
        }
        .donation { .w(1200); .p(80, 40, 0); .mb(210);
          > div {
            h4 { .fs(32, 50);}
            &.live { .p(60, 54, 72, 70); .tl;
              .cost { .w(564); .rt(54, -30);
                .coupon { .pt(44); .pb(0); .tc; .h(184);
                  .value { .fs(64);
                    em {.mr(20);}
                  }
                  .ko { .fs(16); .mt(4); }
                }
                .due { .mt(12); .tr; .regular; .fs(14); }
              }
              .copy { .fs(26, 38);
                &:before { .wh(16); .lt(-20, -12);}
                &:after { .wh(16); .rb(-36, 2);}
              }
            }
            &.thing { .p(36, 80, 32);
              > figure { .wh(840, 348); .mb(26); }
              > .message { .w(auto); .fs(26, 38); }
            }
          }
          > button { .wh(520, 74); .fs(30, 74); }
          &:after { .h(100); .b(-100); }
        }
        .league { .w(1200);
          [loldocup-star-title] { .fs(34); .mb(80);
            > .l { .t-sxy(.8, 0, -65%);
              &.left { .l(-144); }
              &.right { .r(-144); }
            }
          }
          &:before { .h(100); .t(-100); }
          [slider] { .mb(160); }
          .swiper-container { .p(0, 78);}
          .swiper-slide {.mt(80);
            .p-item { .pl(488);
              > img { .wh(488, 340); }
              > .info-holder { .h(308); .p(24, 46);
                h4 { .fs(28, 32); .m(16, 0, 28); .min-h(64);}
                ul {
                  li { .fs(16); }
                }
              }
              .apply-btn { .rb(-20, 24); .wh(248, 52); .fs(20, 52);
                &:after { .wh(16); .ml(12);}
              }
            }
          }
        }
      }
    }
  }
}
</style>
